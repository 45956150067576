import auth from '@/middleware/auth'

export default [
	{
		path: '/profile',
		name: 'Perfil',
		icon: 'profile',
		beforeEnter: auth,
		component: () => import('@/pages/profile/Personal')
	},
	{
		path: '/profile/password',
		name: 'Segurança',
		icon: 'roles',
		beforeEnter: auth,
		component: () => import('@/pages/profile/Password')
	}
]
