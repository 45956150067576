import store from '../store'

function auth(to, from, next) {
	const token = localStorage.getItem('@stup:token')
	if (!token) {
		next('login')
	}

	if (store.state.user.permissions) {
		const permission = store.state.user.permissions.find(e => e.slug === to.name.toLowerCase())

		if (to.name.toLowerCase() == 'contests' && !['master', 'manager'].includes(store.state.user.user.role.name)) {
			next('/error')
		}

		if (permission && !permission.role.read) {
			next('error')
		}
	}

	return next()
}

export default auth
