import Vue from 'vue'
import VueRouter from 'vue-router'
import Menu from './menu'
import Auth from './auth'
import User from './user'

Vue.use(VueRouter)

const router = new VueRouter({
	routes: [...Auth, ...Menu, ...User],
	mode: 'history',
	linkExactActiveClass: 'is-active'
	//linkActiveClass: 'is-active'
})

export default router
