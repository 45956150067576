export function setPermissions(state, value) {
	state.permissions = value
}

export function setUser(state, value) {
	state.user = value
}

export function setNotifications(state, value) {
	state.notifications = value
}

export function setNotificationStart(state, value) {
	state.notificationStart = value
}

export function setTotalNotifications(state, value) {
	state.totalNotifications = value
}

export function setActionUrl(state, value) {
	state.actionUrl = value
}

export function setRatingCriteriaId(state, value) {
	state.ratingCriteriaId = value
}

export function setCriteriaContest(state, value) {
	state.criteriaContest = value
}
