import Vue from 'vue'
import App from './App'
import Buefy from 'buefy'
import VueMask from 'v-mask'
import router from './router'
import Roles from './mixins/roles'
import store from './store'
import './validation'
import './signature'

Vue.config.productionTip = false

Vue.use(Buefy)
Vue.use(VueMask)
Vue.mixin(Roles)

new Vue({
	router,
	store,
	render: h => h(App),
	callRoleMixin: true
}).$mount('#app')

Vue.prototype.$callRoleMixin = false
Vue.config.devtools = true
