import auth from '@/middleware/auth'

export default [
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'dashboard',
		beforeEnter: auth,
		component: () => import('@/pages/Dashboard')
	},
	{
		path: '/roles/:page?/:id?',
		name: 'Roles',
		icon: 'roles',
		meta: {
			title: 'Perfis'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Roles')
	},
	{
		path: '/users/:page?/:id?',
		name: 'Users',
		icon: 'users',
		meta: {
			title: 'Usuários'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Users')
	},
	{
		path: '/dealers/:page?/:id?',
		name: 'Dealers',
		icon: 'dealers',
		beforeEnter: auth,
		component: () => import('@/pages/Dealers')
	},
	{
		path: '/categories/:page?/:id?',
		name: 'Categories',
		icon: 'criteria',
		meta: {
			title: 'Critérios'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Categories')
	},
	{
		path: '/ratings/:page?/:id?',
		name: 'Ratings',
		icon: 'rating',
		meta: {
			title: 'Avaliações'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Ratings')
	},
	{
		path: '/contests/:id?',
		name: 'Contests',
		icon: 'contest',
		meta: {
			title: 'Contestações'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Contests')
	},
	{
		path: '/ranking/:id?',
		name: 'Ranking',
		icon: 'ranking',
		meta: {
			title: 'Ranking'
		},
		beforeEnter: auth,
		component: () => import('@/pages/Ranking')
	}
]
