// import Middleware from '@/middleware/roles'

export default {
	data() {
		return {
			roles: {}
		}
	},
	async mounted() {
		// console.log(this.roles, this.call)
		// console.log(this.$callRoleMixin)

		if (!this.$callRoleMixin) {
			// this.roles = await Middleware()
		}
		this.$callRoleMixin = true;
		// if (!this.roles.route.read) {
		// 	await this.$router.push('/404')
		// }
		// console.log(this.roles)
	}
}
